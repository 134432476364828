/*
swap : 폴백 폰트로 글자를 렌더링하고, 웹 폰트 로딩이 완료되면 웹 폰트를 적용한다. 웹 폰트 로딩 여부와 관계없이 항상 텍스트가 보인다.

block : FOIT와 동일하게 작동하는 옵션이다. 웹 폰트가 로딩되지 않았을 때는 텍스트를 렌더링하지 않는다(최대 3초). 웹 폰트 로딩이 완료되면 웹 폰트를 적용한다. 

format을 작성해서 해당폰트를 지원해주는 브라우저에서 해당폰트만 다운
*/
@font-face {
  font-family: "AppleSDGothic";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/AppleSDGothicNeoB.woff2") format("woff2"),
    url("fonts/AppleSDGothicNeoB.woff") format("woff");
}
@font-face {
  font-family: "AppleSDGothic";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/AppleSDGothicNeoR.woff2") format("woff2"),
    url("fonts/AppleSDGothicNeoR.woff") format("woff");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/Poppins-Regular.woff") format("woff");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/Poppins-Bold.woff") format("woff");
}
button,
input,
select,
textarea {
  cursor: pointer;
}
button,
input,
select,
table,
textarea {
  font-family: "AppleSDGothic", sans-serif;
  font-weight: 400;
  font-size: 16px;
  border: 0;
  margin: 0;
  padding: 0;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-family: "AppleSDGothic", sans-serif;
  font-weight: 400;
  font-size: 16px;
}
.lang-en {
  font-family: "Poppins", sans-serif;
}
ol,
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: black;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
span {
  display: inline-block;
}
img {
  vertical-align: middle;
}
textarea {
  outline: none;
}
/* 아이폰 input 둥근테두리 그림자 제거 */
input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  outline: none;
}
input[type="checkbox"] {
  -webkit-appearance: checkbox;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #f2f4f5 inset !important;
}
/* select 화살표 없애기 */

select {
  outline: none;
  background: white;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}

select::-ms-expand {
  display: none;
}
fieldset {
  border: 0;
}
legend {
  display: none;
}
